// Copyright Banque de France. All Rights Reserved.
// This file is the property of Banque de France.
// It cannot be copied and/or distributed without the express
// permission of Banque de France.

import { Injectable } from '@angular/core';
import { Card, MonitoredRoles, Scope, Stats, StatsDto, Totals } from '../../models/dashboard';
import { Mapper } from '../mapper';

@Injectable()
export class StatsMapper implements Mapper<Stats, StatsDto> {
  public static readonly descriptionMap: Record<keyof Totals, Record<MonitoredRoles, string>> = {
    issuance: {
      NationalCBDCSupervisor: 'Total amount issued on all DCWs under the NCB’s jurisdiction',
      DedicatedCashWalletManager: 'Total amount issued on all DCWs managed by the NCB but out of its jurisdiction'
    },
    redemption: {
      NationalCBDCSupervisor: 'Total amount redeemed from all DCWs under the NCB’s jurisdiction',
      DedicatedCashWalletManager: 'Total amount redeemed from all DCWs managed by the NCB but out of its jurisdiction'
    },
    outstanding: {
      NationalCBDCSupervisor: 'Total amount of all balances available on DCWs under the NCB’s jurisdiction',
      DedicatedCashWalletManager:
        'Total amount of all balances available on DCWs managed by the NCB but out of its jurisdiction'
    },
    settled_debited: {
      NationalCBDCSupervisor:
        'Total amount and number of settled transactions debiting DCWs under the NCB’s jurisdiction, except redemption operations',
      DedicatedCashWalletManager:
        'Total amount and number of settled transactions debiting DCWs managed by the NCB but out of its jurisdiction, except redemption operations'
    },
    settled_credited: {
      NationalCBDCSupervisor:
        'Total amount and number of settled transactions crediting DCWs under the NCB’s jurisdiction, except Issuance operations',
      DedicatedCashWalletManager:
        'Total amount and number of settled transactions crediting DCWs managed by the NCB but out of its jurisdiction, except Issuance operations'
    },
    unsettled: {
      NationalCBDCSupervisor:
        'Total number and amount of unsettled transactions debiting DCWs under the NCB’s jurisdiction',
      DedicatedCashWalletManager:
        'Total number and amount of unsettled transactions debiting DCWs managed by the NCB out of its jurisdiction, except transactions initiated on an external network'
    }
  };

  public static readonly scopeMap: Record<MonitoredRoles, Scope> = {
    NationalCBDCSupervisor: 'National supervised scope',
    DedicatedCashWalletManager: 'Outsourced scope'
  };

  toModel(dto: StatsDto): Stats {
    let stats: Stats = {
      items: [],
      lastUpdate: dto.metadata.last_update
    };

    for (const key in dto.totals) {
      const value = dto.totals[key];

      const cards: Card[] = [
        {
          type: 'issuance',
          title: 'Minted since start of day',
          scope: StatsMapper.scopeMap[key],
          amount: value.issuance?.amount,
          description: StatsMapper.descriptionMap.issuance[key]
        },
        {
          type: 'redemption',
          title: 'Burnt since start of day',
          scope: StatsMapper.scopeMap[key],
          amount: value.redemption?.amount,
          description: StatsMapper.descriptionMap.redemption[key]
        },
        {
          type: 'outstanding',
          title: 'Outstanding amount',
          scope: StatsMapper.scopeMap[key],
          amount: value.outstanding?.amount,
          description: StatsMapper.descriptionMap.outstanding[key]
        },

        {
          type: 'settled_debited',
          title: 'Settled in DEBIT since start of day',
          scope: StatsMapper.scopeMap[key],
          amount: value.settled_debited?.amount,
          count: value.settled_debited?.count,
          description: StatsMapper.descriptionMap.settled_debited[key]
        },
        {
          type: 'settled_credited',
          title: 'Settled in CREDIT since start of day',
          scope: StatsMapper.scopeMap[key],
          amount: value.settled_credited?.amount,
          count: value.settled_credited?.count,
          description: StatsMapper.descriptionMap.settled_credited[key]
        },
        {
          type: 'unsettled',
          title: 'Unsettled since start of day',
          scope: StatsMapper.scopeMap[key],
          amount: value.unsettled?.amount,
          count: value.unsettled?.count,
          description: StatsMapper.descriptionMap.unsettled[key]
        }
      ];
      stats.items.push({ key, value: cards });
    }

    // Order by scopeMap keys
    stats.items = stats.items.sort(function (a, b) {
      return Object.keys(StatsMapper.scopeMap).indexOf(a.key) - Object.keys(StatsMapper.scopeMap).indexOf(b.key);
    });

    return stats;
  }

  toModels(dtos: StatsDto[]): Stats[] {
    throw new Error('Method not implemented.');
  }

  toDto(entity: Stats): StatsDto {
    throw new Error('Method not implemented.');
  }

  toDtos(entities: Stats[]): StatsDto[] {
    throw new Error('Method not implemented.');
  }
}
