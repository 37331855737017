// Copyright Banque de France. All Rights Reserved.
// This file is the property of Banque de France.
// It cannot be copied and/or distributed without the express
// permission of Banque de France.

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, forkJoin, Observable, OperatorFunction } from 'rxjs';
import { BaseHttpRequestOptionsService } from '../common/base-http-request-options.service';
import { environment } from '@env/environment';
import { ClientDto, EntityDto, PatchEntityDto } from '@app/models/entities/entity.dto';
import { map } from 'rxjs/operators';
import { Routing } from '@app/core/enum/routing.enum';
import { of } from 'rxjs';
import { TrimTool } from '../common/trim-tool/trim-tool';
import { CashRole } from '@models/roles/role';
import { Tools } from '@app/services/common/tools';
import { ObjectVersion } from '@models/common/reference-data.interface';

@Injectable()
export class EntitiesService {
  private readonly _baseOctopusUrl: string = `${environment.octopusUrl}api/octopus`;
  private entitiesCache: { [index: string]: EntityDto } = {};

  public constructor(
    private httpClient: HttpClient,
    private baseHttpRequestOptionsService: BaseHttpRequestOptionsService
  ) {}

  public getEntities(): Observable<EntityDto[]> {
    const listUrl: string = `${this._baseOctopusUrl}${Routing.Entities}`;
    const header: { headers: HttpHeaders } = this.baseHttpRequestOptionsService.create();
    return this.httpClient.get<EntityDto[]>(listUrl, header).pipe(
      map((entities: EntityDto[]) =>
        entities.map((entity: EntityDto) => {
          entity.isCentralBank = entity.roles_table.includes(CashRole.NATIONALCBDCSUPERVISOR);
          entity.isOperator = entity.roles_table.includes(CashRole.DL3SOPERATOR);
          entity.isNCBIssuer = entity.roles_table.includes(CashRole.CASHSUPER);
          this.entitiesCache[entity.entity_id] = entity;
          return entity;
        })
      )
    );
  }

  public getEntitiesCacheMap(): Observable<{ [index: string]: EntityDto }> {
    if (Object.keys(this.entitiesCache).length == 0) {
      return this.getEntities().pipe(() => of(this.entitiesCache));
    }
    return of(this.entitiesCache);
  }

  public getMappingOfAllEntities(): Observable<EntityMapping> {
    const ignoreErrors: OperatorFunction<EntityDto[], any> = catchError(() => of([]));
    const allEntitiesObservables: Observable<EntityDto[]>[] = [this.getEntities().pipe(ignoreErrors)];
    const isCustodianButNotCashManager: boolean =
      Tools.hasAppRole(Tools.roles.CASHWALLCUSTODIAN) && !Tools.hasAppRole(Tools.roles.CASHWALLMNG);
    if (isCustodianButNotCashManager) {
      allEntitiesObservables.push(this.getClients().pipe(ignoreErrors));
    }

    return forkJoin(allEntitiesObservables).pipe(
      map((allEntities: EntityDto[][]): Record<string, EntityDto> => {
        const entityMapping: EntityMapping = {};
        if (allEntities) {
          allEntities.map((entities: EntityDto[]) => {
            if (entities) {
              return entities.map((entity: EntityDto): void => {
                entityMapping[entity.entity_id] = entity;
              });
            }
          });
        }
        return entityMapping;
      })
    );
  }

  public getClients(): Observable<ClientDto[]> {
    const listUrl: string = `${this._baseOctopusUrl}${Routing.Clients}`;
    const header: { headers: HttpHeaders } = this.baseHttpRequestOptionsService.create();
    return this.httpClient.get<ClientDto[]>(listUrl, header);
  }

  public getClientsHistory(): Observable<Array<ObjectVersion<EntityDto>>> {
    const listUrl: string = `${this._baseOctopusUrl}${Routing.HistoryClients}`;
    const header: { headers: HttpHeaders } = this.baseHttpRequestOptionsService.create();
    return this.httpClient.get<Array<ObjectVersion<EntityDto>>>(listUrl, header);
  }

  public getClient(entityID: string): Observable<EntityDto> {
    const url: string = `${this._baseOctopusUrl}${Routing.Clients}/${entityID}`;
    const headers: { headers: HttpHeaders } = this.baseHttpRequestOptionsService.create();
    return this.httpClient.get<EntityDto>(url, headers);
  }

  public getEntity(entityID: string, fromCache?: boolean): Observable<EntityDto> {
    if (fromCache) {
      if (this.entitiesCache[entityID]) {
        return of(this.entitiesCache[entityID]);
      }
    }
    const url: string = `${this._baseOctopusUrl}${Routing.Entities}/${entityID}`;
    const headers: { headers: HttpHeaders } = this.baseHttpRequestOptionsService.create();
    return this.httpClient.get<EntityDto>(url, headers);
  }

  public getEntityHistory(entityID: string): Observable<Array<ObjectVersion<EntityDto>>> {
    const url: string = `${this._baseOctopusUrl}${Routing.HistoryEntities}/${entityID}`;
    const headers: { headers: HttpHeaders } = this.baseHttpRequestOptionsService.create();
    return this.httpClient.get<Array<ObjectVersion<EntityDto>>>(url, headers);
  }

  public getEntitiesHistory(): Observable<Array<ObjectVersion<EntityDto>>> {
    const url: string = `${this._baseOctopusUrl}${Routing.HistoryEntities}`;
    const headers: { headers: HttpHeaders } = this.baseHttpRequestOptionsService.create();
    return this.httpClient.get<Array<ObjectVersion<EntityDto>>>(url, headers);
  }

  public patchEntity(entity: PatchEntityDto): Observable<EntityDto> {
    let updateUrl: string = `${this._baseOctopusUrl}${Routing.UpdateEntities}`;
    updateUrl = updateUrl.replace(':id', entity.entityID);
    const header: { headers: HttpHeaders } = this.baseHttpRequestOptionsService.create();
    return this.httpClient.patch<EntityDto>(updateUrl, entity, header);
  }

  public createEntity(entity: EntityDto): Observable<EntityDto> {
    const createUrl = `${this._baseOctopusUrl}${Routing.Entities}`;
    const header = this.baseHttpRequestOptionsService.create();
    const trimmedInstruction: EntityDto = TrimTool.applyTrimOnObject<EntityDto>(entity);
    return this.httpClient.post<EntityDto>(createUrl, trimmedInstruction, header);
  }

  public updateEntity(entity: EntityDto): Observable<EntityDto> {
    const updateEntityUrl = `${this._baseOctopusUrl}${Routing.Entities}`;
    const header = this.baseHttpRequestOptionsService.create();
    const trimmedInstruction: EntityDto = TrimTool.applyTrimOnObject<EntityDto>(entity);
    return this.httpClient.put<EntityDto>(updateEntityUrl, trimmedInstruction, header);
  }

  public updateEntityClient(entity: EntityDto): Observable<EntityDto> {
    const updateClientUrl = `${this._baseOctopusUrl}${Routing.Clients}`;
    const header = this.baseHttpRequestOptionsService.create();
    const trimmedInstruction: EntityDto = TrimTool.applyTrimOnObject<EntityDto>(entity);
    return this.httpClient.put<EntityDto>(updateClientUrl, trimmedInstruction, header);
  }
}

export type EntityName = string;
export type EntityMapping = Record<EntityName, EntityDto>;
