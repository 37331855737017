import { CANCELLED } from "dns";

export enum TransactionState {
    INITIALIZED = 'INITIALIZED',
    ACCEPTED = 'ACCEPTED',
    SETTLED = 'SETTLED',
    UNSETTLED = 'UNSETTLED',
    REJECTED = 'REJECTED',
    PENDING_SETTLEMENT = 'PENDING_SETTLEMENT',
    CANCELLED = 'CANCELLED',
  }