// Copyright Banque de France. All Rights Reserved.
// This file is the property of Banque de France.
// It cannot be copied and/or distributed without the express
// permission of Banque de France.

import { CommonModule, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { AppConfig } from '@app/app.config';
import { SharedModule } from '@app/components/shared/shared.module';
import { AuthenticationService } from '@app/services/authentication/authentication.service';
import { UserService } from '@app/services/authentication/user.service';
import { EntitiesService } from '@app/services/entities/entities.service';
import { ParticipantsService } from '@app/services/participants/participants.service';
import { environment } from '@env/environment';
import { IdentityProviderOptions, IdentityProviders } from '@env/environment.model';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ToastrModule } from 'ngx-toastr';
import { firstValueFrom, of } from 'rxjs';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderBarComponent } from './components/header-bar/header-bar.component';
import { LocalFilterModule } from './components/logged-in/local-filter.module';
import { LoginComponent } from './components/login/login.component';
import { LoaderComponent } from './components/shared/loader/loader.component';
import { KeysPipe } from './filters/keysValuesFilter.pipe';
import { LimitToFilterPipe } from './filters/limitToFilter.pipe';
import { CbdcDraftMapper, CbdcMapper, CreateTransactionRequestMapper, TransactionMapper } from './mappers';
import { StatsMapper } from './mappers/stats';
import { BaseHttpRequestOptionsService } from './services/common/base-http-request-options.service';
import { ErrorManagerService } from './services/common/error-manager/error-manager.service';
import { FormValidationService } from './services/common/form.validation.service';
import { LoaderInterceptorService } from './services/common/loader/loader.interceptor.service';
import { LoaderService } from './services/common/loader/loader.service';
import { SubscriptionService } from './services/common/subscription.service';
import { ParametersService } from './services/parameters/parameters.service';
registerLocaleData(localeFr);

const baseProviders = [
  AuthenticationService,
  UserService,
  FormValidationService,
  BaseHttpRequestOptionsService,
  SubscriptionService,
  LoaderService,
  EntitiesService,
  {
    provide: HTTP_INTERCEPTORS,
    useClass: LoaderInterceptorService,
    multi: true
  },
  { provide: LOCALE_ID, useValue: 'fr-FR' },
  { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
  ParametersService,
  ErrorManagerService,
  ParticipantsService,
  AppConfig,
  CbdcMapper,
  TransactionMapper,
  CreateTransactionRequestMapper,
  CbdcDraftMapper,
  StatsMapper
];

const keycloakProviders = [
  {
    provide: APP_INITIALIZER,
    useFactory: initializeKeycloak,
    multi: true,
    deps: [AppConfig, KeycloakService]
  }
];

function initializeKeycloak(config: AppConfig, keycloak: KeycloakService) {
  return () =>
    config.load().then(() => {
      const keycloakConf: IdentityProviderOptions | undefined = config.getConfig(
        'identityProvider'
      ) as IdentityProviderOptions;
      if (keycloakConf?.name !== IdentityProviders.KEYCLOAK) {
        return;
      }

      if (!keycloakConf.keycloakConfig) {
        console.error('Could not retrieve identity provider configuration successfully');
        return firstValueFrom(of(false));
      }

      return keycloak.init({
        config: keycloakConf.keycloakConfig,
        initOptions: {
          onLoad: 'check-sso',
          flow: 'standard',
          pkceMethod: 'S256',
          silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html'
        },
        enableBearerInterceptor: true,
        bearerExcludedUrls: ['/assets', '/clients/public']
      });
    });
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderBarComponent,
    LimitToFilterPipe,
    KeysPipe,
    FooterComponent,
    LoaderComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxPermissionsModule.forRoot(),
    LocalFilterModule,
    ToastrModule.forRoot(),
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    KeycloakAngularModule,
    MatTableModule,
    MatPaginatorModule,
    SharedModule,
    MatDatepickerModule,
    MatNativeDateModule
  ],
  providers: [...baseProviders, ...keycloakProviders],
  exports: [LocalFilterModule],
  bootstrap: [AppComponent]
})
export class AppModule {}
