// Copyright Banque de France. All Rights Reserved.
// This file is the property of Banque de France.
// It cannot be copied and/or distributed without the express
// permission of Banque de France.

// RVS Used to display LT drafts list + form
import { TimeStamps } from '@core/timestamps';

export class CBDCOperationDraftModel {
    public cbdcOperationDraftID: string;
    public type: string;
    public techCBDCOperationID: string;
    public senderID: string;
    public instructingPartyID: string;
    public onBehalfUser: string;
    public externalNetwork: boolean;
    public amountTransferred: string;
    public currency: string;
    public debitedCashWalletAlias: string;
    public debitedCashWalletManagerID: string;
    public debitedNetworkID: string;
    public creditedCashWalletAlias: string;
    public creditedCashWalletManagerID: string;
    public creditedNetworkID: string;
    public etatsUX: string;
    public historicStatus: string[];
    public timestamps: TimeStamps;
    public isValidated: boolean;
    public creationDate: string;
    public initiatorUserUUID: string;
    public initiatorUserName: string;
    public approverUserUUID: string;
    public approverUserName: string;
    public statusTimestamp: string;
    public operationContext?: string;
    public settlementType?: string;
    public ISD?: string;
    public ISDTimestamp?: number;
    public supplementaryData?: string;
    public cbdcRequestType?: string;
    public poaID?: string;
}
