// Copyright Banque de France. All Rights Reserved.
// This file is the property of Banque de France.
// It cannot be copied and/or distributed without the express
// permission of Banque de France.

// RVS DTO, used in create transaction function only

import { TimeStamps } from '@core/timestamps';

export class TransactionRequestDto {
  public constructor(
    public instructionID: string,
    public type: string,
    public amountTransferred: string,
    public currency: string,
    public debitedCashWalletAlias: string,
    public debitedCashWalletManagerID: string,
    public debitedNetworkID: string,
    public creditedCashWalletAlias: string,
    public creditedCashWalletManagerID: string,
    public creditedNetworkID: string,
    public onBehalfUser: string,
    public etatsUX: string,
    public historicStatus: string[],
    public timestamps: TimeStamps,
    public isValidated: boolean,
    public creationDate: string,
    public initiatorUserUUID: string,
    public initiatorUserName: string,
    public approverUserUUID: string,
    public approverUserName: string,
    public instructingPartyID?: string,
    public operationContext?: string,
    public cbdcRequestType?: string,
    public settlementType?: string,
    public ISD?: Date | string,
    public ISDTimestamp?: number,
    public supplementaryData?: string,
    public poaID?: string
  ) {}
}
