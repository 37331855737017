// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

import { EnvironmentModel, IdentityProviders } from './environment.model';

export const environment: EnvironmentModel = {
  production: true,
  debug: null,
  apiUrl: '',
  octopusUrl: '',
  accessUrl: '',
  theme: '',
  settlementEngineType: '',
  CashNaming: '',
  CashOperationNaming: '',
  appTitle: '',
  logoTitle: '',
  newConfigSystem: true,
  issuerCashWalletAlias: '',
  issuerCashWalletManagerID: '',
  cashOnly: true,
  environment: '',
  identityProvider: {
    name: IdentityProviders.ACCESS
  },
  disableBusinessWindows: false
};
