// Copyright Banque de France. All Rights Reserved.
// This file is the property of Banque de France.
// It cannot be copied and/or distributed without the express
// permission of Banque de France.

import { Injectable } from '@angular/core';
import { Timestamp } from '@core/timestamps';
import { CbdcType, Transaction, TransactionDetails, TransactionDto, Type } from '../models/transaction';
import { TransactionState } from '../models/transaction/enums/transaction-state.enum';
import { Mapper } from './mapper';

@Injectable()
export class TransactionMapper implements Mapper<Transaction, TransactionDto> {
  toModel(dto: TransactionDto): Transaction {
    const settlementTimestamp: Timestamp | undefined =
      dto.timestamps[TransactionState.SETTLED] ??
      dto.timestamps[TransactionState.UNSETTLED] ??
      dto.timestamps[TransactionState.REJECTED];

    let type = dto.type?.toLowerCase() as Type;
    if (dto.cbdcRequestType === 'PAYMENT') type = 'payment';

    return {
      id: dto.instructionLTID,
      status: dto.etatsUX,
      type: type,
      currency: dto.currency,
      amount: dto.amountTransferred,
      creditedWalletId: dto.creditedCashWalletAlias,
      creditedManagerId: dto.creditedCashWalletManagerID,
      debitedWalletId: dto.debitedCashWalletAlias,
      debitedManagerId: dto.debitedCashWalletManagerID,
      statusTimestamp: dto.timestamps[dto.etatsUX].calendarDate,
      cbdcType: dto.cbdcRequestType as CbdcType,
      senderID: dto.senderID,
      instructingPartyID: dto.instructingPartyID,
      acceptanceTimestamp: dto.timestamps[TransactionState.ACCEPTED]?.calendarDate ?? '',
      settlementCalendarTimestamp: settlementTimestamp?.calendarDate,
      settlementBusinessTimestamp: settlementTimestamp?.businessDate,
      onBehalfOwner: dto.onBehalfOwner,
      onBehalfUser: dto.onBehalfUser,
      redeemCashWalletAlias: dto.redeemCashWalletAlias,
      debitedCashWalletManagerID: dto.debitedCashWalletManagerID,
      debitedT2AccountID: dto.debitedT2AccountID,
      debitedNetworkID: dto.debitedNetworkID,
      issuanceCashWalletAlias: dto.issuanceCashWalletAlias,
      creditedCashWalletManagerID: dto.creditedCashWalletManagerID,
      creditedCashWalletOwnerID: dto.creditedCashWalletOwnerID,
      creditedCashWalletUserID: dto.creditedCashWalletUserID,
      creditedNetworkID: dto.creditedNetworkID,
      creationDate: dto.creationDate,
      debitedCountryCode: dto.debitedCountryCode,
      creditedCountryCode: dto.creditedCountryCode,
      initiatorUserName: dto.initiatorUserName,
      approverUserName: dto.approverUserName,
      settlementType: dto.settlementType,
      ISD: dto.ISD,
      ISDTimestamp: dto.ISDTimestamp,
      supplementaryData: dto.supplementaryData,
      operationContext: dto.operationContext,
      poaID: dto.poaID,
      settlementDate: dto.settlementDate
    };
  }

  toModels(dtos: TransactionDto[]): Transaction[] {
    const uniqueTransactionsMap = new Map<string, TransactionDto>();
    for (const dto of dtos) {
      const existingItem: TransactionDto | undefined = uniqueTransactionsMap.get(dto.instructionLTID);

      /**
       * Keep debited for duplicate
       */
      if (!existingItem || dto.historicStatus.length > existingItem.historicStatus.length) {
        uniqueTransactionsMap.set(dto.instructionLTID, dto);
      }
    }

    const transactions: Transaction[] = [];

    for (let [_, value] of uniqueTransactionsMap) {
      const tx = this.toModel(value);
      transactions.push(tx);
    }

    return transactions;
  }

  toDetailsModel(dto: TransactionDto): TransactionDetails {
    const transactionDetails: TransactionDetails = this.toModel(dto);
    transactionDetails.timestamps = dto.timestamps;
    transactionDetails.historicStatus = dto.historicStatus;
    transactionDetails.initiator = dto.initiatorUserName;
    transactionDetails.statusRootCause = dto.etatsUXRootCause;

    return transactionDetails;
  }

  toDto(entity: Transaction): TransactionDto {
    throw new Error('Method not implemented.');
  }

  toDtos(entities: Transaction[]): TransactionDto[] {
    throw new Error('Method not implemented.');
  }
}
