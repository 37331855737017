// Copyright Banque de France. All Rights Reserved.
// This file is the property of Banque de France.
// It cannot be copied and/or distributed without the express
// permission of Banque de France.

import { Injectable } from '@angular/core';
import { Mapper } from './mapper';
import { CreateTransaction, TransactionRequestDto } from '@models/transaction';

@Injectable()
export class CreateTransactionRequestMapper implements Mapper<CreateTransaction, TransactionRequestDto> {
  toDto(transactionValues: any): TransactionRequestDto {
    let stringDate = '';
    if (transactionValues.ISD) {
      const isd = new Date(transactionValues.ISD);
      const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(isd);
      const month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(isd);
      const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(isd);
      stringDate = `${year}-${month}-${day}`;
    }

    return new TransactionRequestDto(
      transactionValues.instructionID,
      transactionValues.type,
      transactionValues.amountTransferred,
      transactionValues.currency,
      transactionValues.debitedCashWalletAlias,
      transactionValues.debitedCashWalletManagerID,
      '',
      transactionValues.creditedCashWalletAlias,
      transactionValues.creditedCashWalletManagerID,
      transactionValues.creditedNetworkID,
      transactionValues.onBehalfUser,
      transactionValues.etatsUX,
      transactionValues.historicStatus,
      transactionValues.timestamps,
      transactionValues.isValidated,
      transactionValues.creationDate,
      transactionValues.initiatorUserUUID,
      transactionValues.initiatorUserName,
      transactionValues.approverUserUUID,
      transactionValues.approverUserName,
      transactionValues.instructingPartyID,
      transactionValues.operationContext,
      transactionValues.cbdcRequestType,
      '',
      stringDate,
      0,
      transactionValues.supplementaryData,
      transactionValues.poaID
    );
  }

  toModel(dto: TransactionRequestDto): CreateTransaction {
    throw new Error('Method not implemented.');
  }

  toModels(dtos: TransactionRequestDto[]): CreateTransaction[] {
    throw new Error('Method not implemented.');
  }
  
  toDtos(entities: CreateTransaction[]): TransactionRequestDto[] {
    throw new Error('Method not implemented.');
  }
}
